import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLabel from './label.reducer';
import { selectRouteNestedParam } from './../../store/router/router.selectors';

export const selectLabelState = createFeatureSelector<fromLabel.State>(fromLabel.labelFeatureKey);
import * as _ from 'lodash';

const listSelectors = fromLabel.listAdapter.getSelectors((state: any) => state.list);

/**
 * Get total amount of Labels loaded into the store
 */
export const getLabelsTotalInStore = createSelector(selectLabelState, listSelectors.selectTotal);

/**
 * Get list config with current total in store
 */
export const getLabelListConfigWithCurrentTotal = createSelector(selectLabelState, getLabelsTotalInStore, (state: fromLabel.State, currentTotal) => ({
  ..._.get(state, ['list', 'listConfig']),
  page: 0,
  // we calculate a limit which is at least the number of entities in the store, uprounded to a full new page regarding to the limit
  limit:
    currentTotal !== 0
      ? Math.ceil(currentTotal / _.get(state, ['list', 'listConfig', 'limit'], 10)) * _.get(state, ['list', 'listConfig', 'limit'], 10)
      : _.get(state, ['list', 'listConfig', 'limit'])
}));

/**
 * Get list config (sorting etc.)
 */
export const getLabelListConfig = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'listConfig'], {}));

/**
 * Get Label list
 */
export const getLabels = createSelector(selectLabelState, listSelectors.selectAll);

export const getLabelsOnly = createSelector(selectLabelState, getLabels, (state: fromLabel.State, labels) => _.map(labels, 'label'));

/**
 * Get Label list meta data (total etc.)
 */
export const getLabelsMeta = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'meta'], { total: 0, count: 0, offset: 0, limit: 0, page: 0 }));

/**
 * Get Label list links, to load next bunch of data
 */
export const getLabelsLinks = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'links']));

/**
 * Get if Label list is loading
 */
export const isLabelListLoading = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'loading'], false));

/**
 * Get if Label list is loading next bunch of data
 */
export const isLabelListLoadingNext = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'loadingNext'], false));

/**
 * get if Label list is loaded
 */
export const isLabelListLoaded = createSelector(selectLabelState, (state: fromLabel.State) => _.get(state, ['list', 'loaded'], false));
