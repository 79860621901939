import { createReducer, on } from '@ngrx/store';
import * as LabelActions from './label.actions';
import { HttpErrorResponse } from '@angular/common/http';

import { Label, RequestOptions, Meta, Links, LabelsGetResponse } from '@gridscale/gsclient-js';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';


export const labelFeatureKey = 'label';

export const adapter: EntityAdapter<Label> = createEntityAdapter <Label>({
  selectId: (a => a.label!), // define "primary id"
  sortComparer: false
});


export const listAdapter: EntityAdapter<Label> = createEntityAdapter <Label>({
  selectId: (a => a.label!), // define "primary id"
  sortComparer: false
});

const listAdapterInitialState = listAdapter.getInitialState({

  listConfig: {
    limit: 10,
    sort: '-label',
  },
  loaded: false,
  loading: false,
  loadingNext: false,
}) as ListState;


export interface ListState extends EntityState<Label> {

  listConfig: RequestOptions;
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: Links<LabelsGetResponse>;
  error?: Error | HttpErrorResponse | undefined;
}



export interface State  {






  list: ListState;
}
export const initialState: State = {





  list: listAdapterInitialState

};






export const reducer = createReducer(
  initialState,


  on(LabelActions.setListConfig, (state, action) => {
    if (_.isEqual(_.get(state, 'list.listConfig', {}), action.config)) {
      return state;
    }
    return {
    // update list config / empty collection
      ...state,

      list: listAdapter.removeAll({
        ...state.list,
        loaded: false,
        listConfig: {
          // ...state.listConfig,
          ...action.config
        }
      })
    };
  }),

  on(LabelActions.loadLabels, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: true
    }

  })),



  on(LabelActions.loadLabelsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
      ...state,

      list: listAdapter.setAll(_.toArray(action.result), {
        ...state.list,
        meta: action.meta,
        links: action.links,
        loading: false,
        loaded: true,
        error: undefined
      })

  })),

  on(LabelActions.loadLabelsFailure, (state, action) => ({
    ...state,

    list: listAdapter.removeAll({
      ...state.list,
      loading: false,
      loaded: true,
      error: action.error
    })

  })),


  on(LabelActions.updateLabels, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: true
    }


  })),


  on(LabelActions.updateLabelsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
      ...state,

      list: listAdapter.setAll(_.toArray(action.result), {
        ...state.list,
        meta: action.meta,
        loading: false,
        loaded: true,
        error: undefined
      })

  })),

  on(LabelActions.updateLabelsFailure, (state, action) => ({
    ...state,

    list: listAdapter.removeAll({
      ...state.list,
      loading: false,
      loaded: true,
      error: action.error
    })


  })),





  on(LabelActions.loadLabelsNext, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: _.get(state, ['list', 'links', 'next']) !== undefined ? true : false,
      loadingNext: _.get(state, ['list', 'links', 'next']) !== undefined ? true : false
    }

  })),

  on(LabelActions.loadLabelsNextSuccess, (state: State, action) => ({
    // upsert entities when next page loaded...
      ...state,

      list: listAdapter.upsertMany(_.toArray(action.result), {
        ...state.list,
        meta: action.meta,
        links: action.links,
        loading: false,
        loadingNext: false,
        loaded: true,
        error: undefined
      })

  })),

  on(LabelActions.loadLabelsNextFailure, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: false,
      loadingNext: false,
      loaded: true,
      error: action.error
    }

  })),





















  /******* CUSTOM REDUCERS FROM HERE ******/
);

