import { createAction, props } from '@ngrx/store';


import { RequestPollResult, RequestOptions, Meta, Links, LabelIndex, LabelsGetResponse } from '@gridscale/gsclient-js';


export const actionPrefix = '[label]';


/********************************
 *          LIST ACTIONS        *
 ********************************/

export const setListConfig = createAction(
  actionPrefix + ' Set Label List Config',
  props<{ config: RequestOptions }>()
);


export const loadLabels = createAction(
  actionPrefix + ' Load Label List'
);

export const loadLabelsSuccess = createAction(
  actionPrefix + ' Load Label List Success',
  props<{ result: LabelIndex, meta?: Meta, links?: Links<LabelsGetResponse> }>()
);

export const loadLabelsFailure = createAction(
  actionPrefix + ' Load Label List Failure',
  props<{ error: Error }>()
);

export const updateLabels = createAction(
  actionPrefix + ' Update Label List'
);

export const updateLabelsSuccess = createAction(
  actionPrefix + ' Update Label List Success',
  props<{ result: LabelIndex, meta?: Meta }>()
);

export const updateLabelsFailure = createAction(
  actionPrefix + ' Update Label List Failure',
  props<{ error: Error }>()
);

export const loadLabelsNext = createAction(
  actionPrefix + ' Load Label List Next'
);

export const loadLabelsNextSuccess = createAction(
  actionPrefix + ' Load Label List Next Success',
  props<{ result: LabelIndex, meta?: Meta, links?: Links<LabelsGetResponse> }>()
);

export const loadLabelsNextFailure = createAction(
  actionPrefix + ' Load Label List Next Failed',
  props<{ error: Error }>()
);


















/********************************
 *      INTERNAL ACTIONS        *
 ********************************/




/******* CUSTOM ACTIONS FROM HERE ******/
